import css from "../../css/error/404/error.module.css";

import React from "react";

const Error = () => {
    return (
        <div className={css.container}>
            <div className={css.wrapper}>
                <div className={css.info}>
                    <h1>Oops!</h1>
                    <h2>Where are we?</h2>
                    <p>
                        The page you are looking for was moved,
                        <br />
                        removed, in the repair shop or never existed.
                    </p>
                    <a href="/" className={css.btn}>
                        Go Home
                    </a>
                </div>

                <div className={css.bear}>
                    <div className={css.zzz} />
                </div>
            </div>
        </div>
    );
};

export default Error;
