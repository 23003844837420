import css from "../css/layout/search.module.css";
import HeaderBase from "./header/HeaderBase";

import Footer from "./footer/Footer";

import React from "react";
import PropTypes from "prop-types";

const LayoutSearch = (props) => {
    const { children, withoutFooter, stickyHeader } = props;

    return (
        <div className={css.wrapper}>
            <HeaderBase stickyHeader={stickyHeader} />
            {children}
            {!withoutFooter && <Footer />}
        </div>
    );
};

LayoutSearch.defaultProps = {
    withoutFooter: false,
    stickyHeader: false,
};

LayoutSearch.propTypes = {
    withoutFooter: PropTypes.bool,
    stickyHeader: PropTypes.bool,
};

export default LayoutSearch;
