import css from "../../css/header/header-base.module.css";
import cssMenu from "../../css/header/menu-search.module.css";

import Menu from "./Menu";
import Logo from "./Logo";
import { useAuth } from "../common/Auth";
import useWindowSize from "../../hooks/useWindowSize";

import React from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import Link from "next/link";

const AuthModal = dynamic(() => import("../auth/AuthModal"), { ssr: false });
const MenuProfile = dynamic(() => import("./MenuProfile"), { ssr: false });
const NotificationIcons = dynamic(() => import("./NotificationIcons"), {
    ssr: false,
});

const HeaderBase = (props) => {
    const { loaded, isAuthenticated, profile } = useAuth();
    const { width } = useWindowSize();

    const { stickyHeader, hideMobileMenu } = props;
    const headerClassName = stickyHeader ? css.headerSticky : css.header;

    const showLoginBtn = !parseInt(process.env.DISABLE_LOGIN);

    let warningMessage = null;

    if (isAuthenticated) {
        const { agent } = profile;

        if (agent && agent.no_method === 1) {
            // PRIORITY #1
            warningMessage = {
                url: "/user/billing/payment-method",
                short: "Add Payment Method",
                full: "Add a new Payment Method",
            };
        } else if (profile.should_change_password === 1) {
            // PRIORITY #2
            warningMessage = {
                url: "/user/account-settings/password",
                short: "Update your Password",
                full: "Update your account Password",
            };
        }
    }

    return (
        <div className={headerClassName}>
            <div className={css.logo}>
                <Logo size="sm" />

                {warningMessage && (
                    <Link href={warningMessage.url} className={css.menuLink2}>
                        {warningMessage.short}
                    </Link>
                )}
            </div>

            {!(hideMobileMenu && width <= 990) && (
                <div className={css.menu}>
                    <Menu theme={cssMenu} burgerIcon="black" />
                </div>
            )}

            {warningMessage && (
                <div>
                    <Link href={warningMessage.url} className={css.menuLink1}>
                        {warningMessage.full}
                    </Link>
                </div>
            )}

            {loaded && isAuthenticated && (
                <div className={css.notificationIcons}>
                    <NotificationIcons />
                </div>
            )}

            {loaded && showLoginBtn && (
                <>
                    {isAuthenticated && (
                        <div className={css.menuProfile}>
                            <MenuProfile />
                        </div>
                    )}
                    {!isAuthenticated && (
                        <div className={css.login}>
                            <AuthModal className={css.signinButton}>
                                Sign In
                            </AuthModal>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

HeaderBase.defaultProps = {
    hideMobileMenu: false,
    stickyHeader: false,
};

HeaderBase.propTypes = {
    hideMobileMenu: PropTypes.bool,
    stickyHeader: PropTypes.bool,
};

export default HeaderBase;
