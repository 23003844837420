import debounce from "lodash.debounce";
import { useState, useEffect } from "react";

export default function useWindowSize() {
    const isBrowser = typeof window !== "undefined";

    const [size, setDimension] = useState({
        width: isBrowser ? window.innerWidth : null,
        height: isBrowser ? window.innerHeight : null,
    });

    useEffect(() => {
        const debouncedResizeHandler = debounce(() => {
            setDimension({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }, 100);

        window.addEventListener("resize", debouncedResizeHandler);

        return () =>
            window.removeEventListener("resize", debouncedResizeHandler);
    }, []);

    return size;
}
