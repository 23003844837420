import LayoutSearch from "../components/LayoutSearch";

import NextError from "next/error";
import Error404 from "../components/error/Error404";

import React from "react";
import * as Sentry from "@sentry/nextjs";

const Error = (props) => {
    const { statusCode, status } = props;

    let errorStatusCode = statusCode;

    if (!errorStatusCode) {
        if (status) {
            errorStatusCode = status;
        } else {
            errorStatusCode = 400;
        }
    }

    errorStatusCode = parseInt(statusCode);

    return (
        <LayoutSearch>
            {errorStatusCode === 404 && <Error404 />}
            {errorStatusCode !== 404 && (
                <NextError statusCode={errorStatusCode} />
            )}
        </LayoutSearch>
    );
};

Error.getInitialProps = async (ctx) => {
    // In case this is running in a serverless function, await this in order to give Sentry
    // time to send the error before the lambda exits
    await Sentry.captureUnderscoreErrorException(ctx);

    const { res, err } = ctx;

    const statusCode = res ? res.statusCode : err ? err.statusCode : 400;

    return {
        statusCode,
    };
};

export default Error;
